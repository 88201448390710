import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styles from '../css/error.module.css';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Seo from '../components/Seo';
const error = () => {
	return (
		<Layout>
			<Seo title='404' />
			<header className={styles.error}>
				<Banner title={`Oeps... pagina niet gevonden`}>
					<AniLink fade className='btn-white' to='/'>
						Terug naar home
					</AniLink>
				</Banner>
			</header>
		</Layout>
	);
};

export default error;
