import React from 'react'
import styles from '../css/banner.module.css'
const Banner = ({ title, subtitle, children }) => {
  return (
    <div className={styles.banner}>
      <h1>{title}</h1>
      <p>{subtitle}</p>
      {children}
    </div>
  )
}

export default Banner
